import { SearchQuery } from 'storefront-query-builder'
import config from 'config'

export default {
  data () {
    return {
      subscription: {
        priceString: '',
        itemsToRent: ''
      }
    }
  },
  mounted () {
    this.loadSubscriptionData()
  },
  computed: {
    parsedSubscription () {
      return { price: this.parseDesc(this.subscription.priceString), itemsToRent: this.subscription.itemsToRent }
    }
  },
  methods: {
    async loadSubscriptionData () {
      let searchQuery = new SearchQuery()
      searchQuery = searchQuery
        .applyFilter({ key: 'category_ids', value: { 'in': [config.server.categoryIdsMap.fashionSubscriptionCategoryId] } });
      ({ items: { 0: { description: this.subscription.priceString, points: this.subscription.itemsToRent } } } = await this.$store.dispatch('product/findProducts', {
        query: searchQuery,
        includeFields: ['description', 'points'],
        size: 1,
        sort: 'final_price:asc'
      }))
    },
    parseDesc (desc) {
      if (desc) {
        return desc.replace(/<\/?p>/g, '')
      } else {
        return ''
      }
    }
  }
}
