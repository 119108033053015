<template>
  <div
    class="searchpanel fixed mw-100 bg-cl-primary cl-accent"
    data-testid="searchPanel"
  >
    <div class="m0 px40 relative">
      <h2 class="pt30 search-panel pb10 weight-500 m0 cl-black">
        {{ $t('Type what you are looking for') }}
      </h2>
      <i
        class="icon-close pointer cl-black fs16 close-icon-row"
        @click="closeSearchpanel"
        data-testid="closeSearchPanel"
      />
    </div>
    <div class="container">
      <div class="row my20">
        <div v-for="productType in productTypes" :key="productType.label" class="radio-input relative px8">
          <input
            :id="productType.label"
            type="radio"
            name="productType"
            @change="makeSearch"
            :value="productType.values"
            v-model="selectedProductType"
          >
          <label :for="productType.label" class="radioStyled">
            {{ productType.label }}
          </label>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mx0 mt20 mb10 between-sm">
        <div
          class="search__container end-xs"
          :class="(search.length &&visibleProducts.length) ? 'active' : 'basic'"
        >
          <label for="search" class="visually-hidden">
            {{ $t('Search') }}
          </label>
          <div class="search-input-group">
            <input
              ref="search"
              id="search"
              v-model="search"
              @input="makeSearch"
              @blur="$v.search.$touch()"
              @keyup.enter="toggleQueryFilter"
              class="search-panel-input px20"
              :placeholder="$t('Search')"
              type="text"
              autofocus="true"
            >
          </div>
        </div>
        <button-full
          v-show="search.length && visibleProducts.length"
          @click.native="toggleQueryFilter"
          class="button__show-all button__show-all--search pointer weight-700 hidden-xs"
        >
          {{ $t('Show all results') }}
        </button-full>
      </div>
      <div v-if="visibleProducts.length && categories.length > 1" class="categories">
        <category-panel :categories="categories" v-model="selectedCategoryIds" />
      </div>
      <div class="product-listing row">
        <product-tile
          v-for="product in visibleProducts"
          class="col-sm-4"
          :key="product.id"
          :product="product"
          :subscription="parsedSubscription"
          @click.native="closeSearchpanel"
        />
        <transition name="fade">
          <div
            v-if="getNoResultsMessage"
            class="no-results relative center-xs h4 col-md-12 cl-black"
          >
            {{ $t(getNoResultsMessage) }}
          </div>
        </transition>
      </div>
      <div
        v-show="OnlineOnly"
        v-if="visibleProducts.length >= 18"
        class="buttons-set align-center py35 mt20 px40"
      >
        <button
          @click="seeMore" v-if="readMore"
          class="no-outline brdr-none py15 px20 bg-cl-black :bg-cl-th-secondary cl-white fs-medium-small"
          type="button"
        >
          {{ $t('Load more') }}
        </button>
        <button
          @click="closeSearchpanel"
          class="no-outline brdr-none p15 fs-medium-small close-button"
          type="button"
        >
          {{ $t('Close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel'
import ProductTile from 'theme/components/core/ProductTile'
import VueOfflineMixin from 'vue-offline/mixin'
import CategoryPanel from 'theme/components/core/blocks/Category/CategoryPanel'
import { minLength } from 'vuelidate/lib/validators'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import config from 'config'
import SubscriptionDataMixin from 'theme/mixins/subscriptionDataMixin'

export default {
  components: {
    ProductTile,
    ButtonFull,
    CategoryPanel
  },
  mixins: [SearchPanel, VueOfflineMixin, SubscriptionDataMixin],
  validations: {
    search: {
      minLength: minLength(3)
    }
  },
  data () {
    return {
      selectedCategoryIds: [],
      productTypes: [
        {
          label: this.$t('Rent'),
          values: ['configurable', 'sirent']
        },
        {
          label: this.$t('Buy pre-owned'),
          values: ['simple']
        }
      ]
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    visibleProducts () {
      const productList = this.products || []
      if (this.selectedCategoryIds.length) {
        return productList.filter(product => product.category_ids.some(categoryId => {
          return this.selectedCategoryIds.includes(categoryId)
        }))
      }
      return productList
    },
    categories () {
      const categories = this.products
        .filter(p => p.category)
        .map(p => p.category)
        .flat()

      const distinctCategories = Array.from(
        new Set(categories.map(c => c.category_id))
      ).map(catId => categories.find(c => c.category_id === catId))

      return distinctCategories
    },
    getMainCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.mainCategoryId)
    },
    getNoResultsMessage () {
      let msg = ''
      if (!this.$v.search.minLength) {
        msg = 'Searched term should consist of at least 3 characters.'
      } else if (this.emptyResults) {
        msg = 'No results were found.'
      }
      return msg
    }
  },
  methods: {
    updateProductType (value) {
      console.log(value)
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    toggleQueryFilter () {
      if (this.getNoResultsMessage === '') {
        if (this.getMainCategory) {
          this.$router.push({ path: this.categoryLink(this.getMainCategory), query: { query: this.search } })
          this.search = ''
          this.$store.commit('ui/setSidebar', false)
        }
      }
    }
  },
  watch: {
    categories () {
      this.selectedCategoryIds = []
    }
  },
  mounted () {
    // add autofocus to search input field
    this.$refs.search.focus()
    let body = document.getElementsByTagName('body')[0]
    body.classList.add('iphone-scroll')
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('iphone-scroll')
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import "~theme/css/variables/grid";
@import "~theme/css/variables/typography";

.searchpanel {
  height: 100vh;
  width: 800px;
  top: 0;
  right: 0;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .close-icon-row {
    display: flex;
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .container {
    padding-left: 40px;
    padding-right: 40px;

    @media #{$media-xs} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .product-listing {
    padding-top: 30px;
  }

  .search-input-group {
    display: flex;
    border: 2px solid #000000;
  }

  .search-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-panel {
    font-weight: 500;
    font-size: 32px;
    text-shadow: 0 0 #b3b3b3;
  }
  .search__container {
    &.basic {
      flex-basis: 100%;
      max-width: 100%;
    }
    &.active {
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
    }
  }
  .search-panel-input {
    @media (max-width: 767px) {
      height: 40px;
    }
    &::-webkit-input-placeholder {
      text-transform: uppercase;
    }
    font-size: 0.8em;
    width: 100%;
    height: 60px;
    padding-bottom: 0;
    padding-top: 0;
    border: none;
    outline: 0;
    @media #{$media-xs} {
      font-size: 16px;
    }
  }

  .no-results {
    top: 80px;
    width: 100%;
  }

  i:hover {
    opacity: 1;
  }

  .close-button {
    background: #fff;
  }

  button {
    @media #{$media-xs} {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
.radio-input {
  label {
    padding: 10px 0px;
    min-width: 210px;
    text-align: center;
    border: 1px solid #DCDCDC;
    color: #646464;
    display: block;
    font-size: 14px;
    &:before {
      content: "";
      position: absolute;
      left: 30px;
      top: 20px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  input {
    display: none;
    position: absolute;
    top: 3px;
    left: 0;
    &:checked + label {
      border-color: black;
      box-shadow: 0px 0px 0px 2px #3e3e3e !important;
      color: white;
      background-color: black;
    }
    &:hover,
    &:focus {
      + label {
        //border-color: white;
        box-shadow: 0px 0px 0px 1px #3e3e3e;
      }
    }
    &:disabled + label {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
